/* eslint-disable */

import { layers } from './layers';
import { paths } from './paths';
// Get the active document
export const getGroups = {
  pullPaths(layer) {
    if (app.documents.length > 0) {
      var doc = app.activeDocument;
      var activeLayer = doc.activeLayer;
      var pathItems = doc.pathItems;
      var groupItems = doc.groupItems;

      // Get the active artboard
      var artboard = doc.artboards[doc.artboards.getActiveArtboardIndex()];

      //Change path to the path you want to save the file
      var returnString = '';
      returnString += '{"rectangle": [\n';
      returnString += '  {"points": [\n';
      returnString += '  {\n';
      returnString += '   "x": ' + artboard.artboardRect[0] + ',\n';
      returnString += '   "y": ' + artboard.artboardRect[1] + '\n';
      returnString += '   },\n';
      returnString += '  {\n';
      returnString += '   "x": ' + artboard.artboardRect[2] + ',\n';
      returnString += '   "y": ' + artboard.artboardRect[1] + '\n';
      returnString += '  },\n';
      returnString += '  {\n';
      returnString += '   "x": ' + artboard.artboardRect[2] + ',\n';
      returnString += '   "y": ' + artboard.artboardRect[3] + '\n';
      returnString += '  },\n';
      returnString += '  {\n';
      returnString += '   "x": ' + artboard.artboardRect[0] + ',\n';
      returnString += '   "y": ' + artboard.artboardRect[3] + '\n';
      returnString += '  }\n';
      returnString += '  ]\n';
      returnString += ' }\n';
      returnString += '],\n';

      // Get the paths from the artboard
      var paths = artboard.pageItems;

      var layers = getLayerNames();
      var selectedLayer = '';
      for (var y = 0; y < layers.length; y++) {
        if (layers[y] === layer) {
          selectedLayer = layers[y];
          break;
        }
      }

      if (selectedLayer === layer) {
        var layerGroupItems = activeLayer.groupItems;
        returnString += '"groups": [\n';
        var length = 0;
        for (var a = 0; a < layerGroupItems.length; a++) {
          var groupItem = groupItems[a];

          var groupPathItems = groupItem.pathItems;
          returnString += ' {"paths" : [\n';

          for (var z = 0; z < groupPathItems.length; z++) {
            var pathItem = groupPathItems[z];

            if (pathItem.strokeColor.typename == 'GradientColor') {
              var pathLength = pathItem.length;
              var gradient = pathItem.strokeColor.gradient;
              var interval = Math.floor(pathLength / 30); //number of points to sample, could probably change this tbh
              var colors = [];
              var points = [];

              returnString += '  {"points": [\n';

              for (var j = 0; j < pathLength; j += interval) {
                var color = [];
                var samplePoint = pathItem.pathPoints[0].anchor;
                var totalDistance = 0;

                for (var i = 0; i < pathItem.pathPoints.length - 1; i++) {
                  var point1 = pathItem.pathPoints[i].anchor;
                  var point2 = pathItem.pathPoints[i + 1].anchor;
                  var segmentLength = Math.sqrt(
                    Math.pow(point2[0] - point1[0], 2) + Math.pow(point2[1] - point1[1], 2)
                  );

                  if (totalDistance + segmentLength >= j) {
                    var t = (j - totalDistance) / segmentLength;
                    samplePoint = [
                      point1[0] + t * (point2[0] - point1[0]),
                      point1[1] + t * (point2[1] - point1[1]),
                    ];
                    break;
                  }

                  totalDistance += segmentLength;
                }

                var gradientStops = gradient.gradientStops;
                var gradientPosition = j / pathLength;

                // Find the two gradient stops surrounding the current position
                var stop1, stop2;
                for (var k = 0; k < gradientStops.length - 1; k++) {
                  if (
                    gradientPosition >= gradientStops[k].rampPoint &&
                    gradientPosition <= gradientStops[k + 1].rampPoint
                  ) {
                    stop1 = gradientStops[k];
                    stop2 = gradientStops[k + 1];
                    break;
                  }
                }

                var color1 = stop1.color;
                var color2 = stop2.color;

                var cmyk = color1.typename === 'CMYKColor';

                if (cmyk) {
                  var t =
                    (gradientPosition - stop1.rampPoint) / (stop2.rampPoint - stop1.rampPoint);
                  var c = color1.cyan + t * (color2.cyan - color1.cyan);
                  var m = color1.magenta + t * (color2.magenta - color1.magenta);
                  var y = color1.yellow + t * (color2.yellow - color1.yellow);
                  var k = color1.black + t * (color2.black - color1.black);

                  color = [c, m, y, k];
                } else {
                  var t =
                    (gradientPosition - stop1.rampPoint) / (stop2.rampPoint - stop1.rampPoint);

                  var r = color1.red + t * (color2.red - color1.red);
                  var b = color1.blue + t * (color2.blue - color1.blue);
                  var g = color1.green + t * (color2.green - color1.green);

                  color = [r, b, g];
                }
                colors.push(color);
                var colorToStr =
                  color.length === 4
                    ? '[C: ' +
                      color[0] +
                      ', M: ' +
                      color[1] +
                      ', Y: ' +
                      color[2] +
                      ', K: ' +
                      color[3] +
                      ']'
                    : '[R: ' + color[0] + ', G: ' + color[1] + ', B: ' + color[2] + ']';
                returnString += '   {\n    "x": ' + samplePoint[0] + ',\n';
                returnString += '    "y": ' + samplePoint[1] + ',\n';
                returnString += '    "z": 0.0,\n';
                returnString +=
                  '    "value": ' +
                  color[0] +
                  '\n   }' +
                  (j < pathLength - interval ? ',\n' : '\n');
              }
              returnString += '\n  ]';
              returnString += '\n }';
              returnString += '\n   ]';
              returnString += '\n}' + (a < layerGroupItems.length - 1 ? ',\n' : '\n');
            } else if (pathItem.strokeColor.typename == 'RGBColor') {
              length += 1;
              var pathLength = pathItem.length;
              var interval = Math.floor(pathLength / 30); //number of points to sample, could probably change this tbh
              var points = [];
              returnString += '  {"points": [\n';
              for (var c = 0; c < pathLength; c += interval) {
                var samplePoint = pathItem.pathPoints[0].anchor;
                var totalDistance = 0;
                for (var i = 0; i < pathItem.pathPoints.length - 1; i++) {
                  var point1 = pathItem.pathPoints[i].anchor;
                  var point2 = pathItem.pathPoints[i + 1].anchor;
                  var segmentLength = Math.sqrt(
                    Math.pow(point2[0] - point1[0], 2) + Math.pow(point2[1] - point1[1], 2)
                  );
                  if (totalDistance + segmentLength >= c) {
                    var t = (c - totalDistance) / segmentLength;
                    samplePoint = [
                      point1[0] + t * (point2[0] - point1[0]),
                      point1[1] + t * (point2[1] - point1[1]),
                    ];
                    break;
                  }
                  totalDistance += segmentLength;
                }
                returnString += '   {\n    "x": ' + samplePoint[0] + ',\n';
                returnString += '    "y": ' + samplePoint[1] + ',\n';
                returnString += '    "z": 0.0,\n';
                returnString +=
                  '    "value": -1' + '\n   }' + (c < pathLength - interval ? ',\n' : '\n');
              }
              returnString += '  ]';

              returnString += '\n }' + (length < pathItems.length - 1 ? ',' : '');
            }
          }
        }
      }
      returnString += '\n]\n}';

      return returnString;
    } else {
      alert('Please open any document.');
    }
  },
};
