/* eslint-disable */
// These are scripts that will be run in AI (it's JavaScript but it's not run by a web browser)
// For details see: https://ai-scripting.docsforadobe.dev/
export const exportSVG = {
  exportSVG(targetPath) {
    var options = new ExportOptionsWebOptimizedSVG();
    options.embedRasterImages = false;
    options.svgMinify = true;

    app.activeDocument.exportFile(new File(targetPath), ExportType.WOSVG, options);
    return targetPath;
  },
};
