/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Heading, Flex, View, Slider } from '@adobe/react-spectrum';
import { ComposureClient, SolveResponse } from '@nike.innovation/composure-sdk';
import { Button, Box, Text, TextGroup, Card, IconButton, Select, Spinner } from '@nike/eds';
import { AdobeVisualizer, Rhino3dmModel } from '@nike.innovation/visualizer';
import { useState, useEffect } from 'react';
import { Tsai } from '@nike.innovation/ts-ai';
import { useOktaAuth } from '@okta/okta-react';
import useSWR from 'swr';
import { OBJLoader2 } from 'wwobjloader2';
import { BufferGeometry, Mesh } from 'three';
import * as STDLIB from 'three-stdlib';
import * as THREE from 'three';
import { environment } from '../../../../apollo/src/environments/environment';
import { getEntries } from '../../../../composure/src/app/definitions/solves/definition-solve-result-page';

/* eslint-disable-next-line */

export const parseRhinoGeometry = (
  inputContent: string,
  rhino: any
): { data: string; offset: THREE.Vector3 } => {
  const loader = new OBJLoader2().setUseIndices(true);
  const obj = loader.parse(inputContent);
  if (obj.children.length > 0) {
    const originalPosition = new THREE.Vector3().copy(obj.position);

    // Center the model
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(obj);
    boundingBox.getCenter(obj.position);
    obj.position.multiplyScalar(-1);
    obj.updateMatrixWorld();

    // Determine the offset
    const offset = originalPosition.sub(obj.position);
    offset.z = -offset.z;

    // Some obj models are comprised of multiple meshes. These are combined here into a single model.
    const geometryArray: any = [];
    obj.children.forEach(child => {
      if (child.type === 'Mesh') {
        const geometryClone = (child as Mesh).geometry.clone();

        // Bake the current child's world transform matrix into the new clone.
        geometryClone.applyMatrix4(child.matrixWorld);

        // Add the cloned geometry into the array.
        geometryArray.push(geometryClone);
      }
    });
    let mergedGeom: any = new BufferGeometry();
    mergedGeom = STDLIB.mergeBufferGeometries(geometryArray);

    const transparentMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff, // White color
      opacity: 0.8, // 50% transparent
      transparent: true,
    });

    // A few steps to transform the obj data into opennurbs format for rhino/gh
    const mergedMesh = new Mesh(mergedGeom, transparentMaterial);
    const rhinoMesh = rhino.Mesh.createFromThreejsJSON({ data: mergedMesh.geometry });
    const encodedMesh = rhinoMesh.encode();
    return { data: JSON.stringify(encodedMesh), offset };
  }
  return { data: inputContent, offset: new THREE.Vector3(0, 0, 0) };
};

export const readSVG = (tsai: Tsai, path: string) =>
  new Promise<{ svg: string; images: string[] }>((resolve, reject) => {
    // Periodically attempt to read the SVG file
    // (it takes Adobe a while to export)
    const reader = setInterval(() => {
      const result = tsai.readFile(path);
      if (result.err === 0) {
        // Stop reader and remove temp SVG file
        clearInterval(reader);
        tsai.deleteFile(path);

        // Parse the SVG
        const parser = new DOMParser();
        const svgDom = parser.parseFromString(result.data, 'image/svg+xml');

        // Remove all groups but the desired one
        const svgNode = svgDom.getElementsByTagName('svg')[0];
        const { children } = svgNode;
        for (let index = children.length - 1; index >= 0; index -= 1) {
          if (
            children[index].id &&
            children[index].id !== 'cut_x5F_curves_x5F_working' &&
            children[index].id !== 'boundary_x5F_uv'
          ) {
            children[index].remove();
          }
        }

        // Return the updated SVG
        const serializer = new XMLSerializer();
        const simpleSVG = serializer.serializeToString(svgDom);

        const viewBox = svgNode.getAttribute('viewBox')?.split(' ');
        const width = viewBox ? Number(viewBox[2]) : 1;
        const height = viewBox ? Number(viewBox[3]) : 1;

        // Export gradients
        const gradients = [];

        const linearGradients = svgDom.getElementsByTagName('linearGradient');
        for (let index = 0; index < linearGradients.length; index += 1) {
          gradients.push(linearGradients[index]);
        }

        const radialGradients = svgDom.getElementsByTagName('radialGradient');
        for (let index = 0; index < radialGradients.length; index += 1) {
          gradients.push(radialGradients[index]);
        }

        let gradientSvgBase =
          '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs>';
        gradients.forEach(gradient => {
          gradientSvgBase += gradient.outerHTML;
        });
        gradientSvgBase += '</defs>';

        const imagePromises: Promise<string>[] = [];
        gradients.forEach(gradient => {
          const id = gradient.getAttribute('id');

          // Rasterize each gradient
          const gradientSvg = `${gradientSvgBase}<rect width="${width}" height="${height}" fill="url(#${id})"></rect></svg>`;
          const svgToBase64Promise = new Promise<any>((innerResolve, innerReject) => {
            const svgDataBlob = new Blob([gradientSvg], { type: 'image/svg+xml;charset=utf-8' });
            const svgDataURL = URL.createObjectURL(svgDataBlob);

            const img = new Image();
            img.width = width;
            img.height = height;

            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;

              const context = canvas.getContext('2d');
              if (context) {
                context.fillStyle = 'rgb(0,0,0)';
                context.fillRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0, width, height);
                URL.revokeObjectURL(svgDataURL);

                const dataURL = canvas.toDataURL();
                const base64Canvas = dataURL.split(',').pop() || '';
                innerResolve(base64Canvas);
              } else {
                innerReject(new Error());
              }
            };

            img.onerror = () => innerReject(new Error());
            img.src = svgDataURL;
          });

          imagePromises.push(svgToBase64Promise);
        });

        Promise.all(imagePromises).then(images => {
          resolve({ svg: simpleSVG, images });
        });
      }
    }, 100);
  });

export function ThreeDModelPage() {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const [geometry, setGeometry] = useState('');
  const [offset, setOffset] = useState<THREE.Vector3>(new THREE.Vector3(0, 0, 0));
  const [depthMin, setDepthMin] = useState<string>('1');
  const [depthMax, setDepthMax] = useState<string>('5');
  const [exportButtonLoading, setExportButtonLoading] = useState<boolean>(false);
  const [view, setView] = useState<string>('default');
  const [transparency, setTransparency] = useState<number>(0.5);

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const { data: objContent, error: ObjError } = useSWR('midsole model', async () =>
    fetch('/assets/rewild_tooling_04-26-24_uv_3D.obj').then(response => response.text())
  );

  const decoder = new TextDecoder('utf-8');

  const handleRhino = async (rhinoData: any) => {
    const rhino3dm = await window.rhino3dm();
    const result = parseRhinoGeometry(rhinoData, rhino3dm);
    setOffset(result.offset);
    setGeometry(result.data);
  };

  useEffect(() => {
    if (objContent) {
      let readableString: string;
      if (typeof objContent === 'string') {
        readableString = objContent;
      } else {
        readableString = decoder.decode(objContent);
      }

      handleRhino(readableString);
    }
  }, [objContent]);

  const composureClient = new ComposureClient(token, environment.composureEnvironment);

  if (window.cep) {
    const [solveOutput, setSolveOutput] = useState<SolveResponse | string | null>(null);

    if (!window.CSInterface) {
      console.error('CSInterface not found');
      return (
        <View minHeight="100vh" paddingX="size-250" backgroundColor={{ base: 'static-white' }}>
          <Flex>
            <Heading level={1}>
              <div style={{ lineHeight: '28px' }}>
                Digital
                <br />
                Additive
                <br />
                Manufacturing
                <br />
              </div>
            </Heading>
          </Flex>
          <TextGroup>
            <Text>Adobe Illustrator is not supported in this environment.</Text>
          </TextGroup>
        </View>
      );
    }
    const tsai = new Tsai(window);

    const handleSolve = async (
      DepthMin: number,
      DepthMax: number,
      SVG: string,
      Images: string[]
    ) => {
      const key = `${environment.scriptId}`;
      const body = {
        DepthMin,
        DepthMax,
        SVG,
        Images,
      };
      try {
        const response = await composureClient.solve(key, body, true);
        if (response.ok) return response.val;
        console.error('Error in solve', response.err);
        return '';
      } catch (err) {
        console.log(err);
        throw err;
      }
    };

    const handleButtonClick = async () => {
      // Perpare SVG
      const path = `${tsai.userDocumentsDiectory()}/apollo.svg`;
      await tsai.exportSVG(path);
      const data = await readSVG(tsai, path);

      // Submit results
      const solveResult = await handleSolve(
        parseInt(depthMin, 10),
        parseInt(depthMax, 10),
        data.svg,
        data.images
      );
      setSolveOutput(solveResult);
    };

    useEffect(() => {
      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
      };

      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }, []);

    return (
      <View minHeight="100vh" paddingX="size-250" backgroundColor={{ base: 'static-white' }}>
        <Box
          style={{
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            left: 0,
            padding: 'inherit',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <Box height="1em" />
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text font="title-2">Siping</Text>
            <IconButton
              variant="ghost"
              icon="Replay"
              label="Refresh"
              onClick={handleButtonClick}
              size="small"
            />
          </Box>
          <Box height="2em" />
          <Card className="eds-elevation--2 eds-flex--grow-1">
            <AdobeVisualizer position={[0, 0, -350]} view={view}>
              <>
                {objContent && !ObjError && (
                  <Rhino3dmModel
                    key="midsole"
                    data={geometry}
                    material={
                      new THREE.MeshPhongMaterial({
                        color: 0x808080, // Gray color
                        transparent: true,
                        opacity: transparency,
                      })
                    }
                  />
                )}
                {solveOutput &&
                  getEntries(solveOutput).map((model: string) => (
                    <Rhino3dmModel
                      key={model}
                      data={model}
                      material={new THREE.MeshNormalMaterial({ side: THREE.DoubleSide })}
                      position={offset}
                    />
                  ))}
              </>
            </AdobeVisualizer>
            <Box
              style={{
                top: 105,
                right: 47,
                position: 'absolute',
              }}
            >
              <Select
                id="visualizer-view"
                required
                label=""
                hideLabel
                options={[
                  { label: 'Default', value: 'default' },
                  { label: 'Top', value: 'top' },
                  { label: 'Bottom', value: 'bottom' },
                  { label: 'Toe', value: 'front' },
                  { label: 'Heel', value: 'back' },
                  { label: 'Medial', value: 'left' },
                  { label: 'Lateral', value: 'right' },
                ]}
                onChange={e => {
                  setView(e?.value || '');
                }}
              />
              <Slider
                label=""
                value={transparency}
                onChange={value => setTransparency(value)}
                minValue={0}
                maxValue={1}
                step={0.01}
              />
            </Box>
          </Card>
          <Box className="eds-grid eds-grid--s-cols-6">
            <Box className="eds-grid--m-col-3">
              <Slider
                label="Depth Min (mm)"
                value={parseInt(depthMin, 10)}
                onChange={value => setDepthMin(value.toString())}
                minValue={1}
                maxValue={30}
                step={1}
              />
            </Box>
            <Box className="eds-grid--m-col-2">
              <Slider
                label="Depth Max (mm)"
                value={parseInt(depthMax, 10)}
                onChange={value => setDepthMax(value.toString())}
                minValue={1}
                maxValue={30}
                step={1}
              />
            </Box>
          </Box>
          <Box className="eds-grid--m-col-1">
            <Box height="0.5em" />
            <Button
              onClick={async () => {
                setExportButtonLoading(true);
                // Perpare SVG
                const path = `${tsai.userDocumentsDiectory()}/apollo.svg`;
                await tsai.exportSVG(path);
                const data = await readSVG(tsai, path);

                const solveResult = await composureClient.solve(
                  '07302ef6-6f8a-47a6-863e-bb29668fc3bd',
                  {
                    DepthMin: parseInt(depthMin, 10),
                    DepthMax: parseInt(depthMax, 10),
                    SVG: data.svg,
                    Images: data.images,
                  },
                  true
                );
                if (solveResult.ok) {
                  // download the result to a json file
                  const cleanedJsonString = solveResult.val['Design Pack'][0]
                    .replace(/\\r\\n/g, '')
                    .replace(/\\/g, '');
                  const json = JSON.parse(cleanedJsonString);

                  try {
                    const filePath = tsai.openExportDialog('Export Siping Design Pack');
                    // TODO: standardize format of data going in an out of AI
                    // Saving as txt file for now, could easily move to JSON as payload grows more complex
                    await tsai.exportTxtFile(filePath.data, JSON.stringify(json));
                  } catch (err) {
                    console.error(err);
                  }
                }
                setExportButtonLoading(false);
              }}
              disabled={exportButtonLoading}
            >
              {exportButtonLoading ? <Spinner /> : 'Export'}
            </Button>
          </Box>
          <Box height="1em" />
        </Box>
      </View>
    );
  }
}

export default ThreeDModelPage;
